import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper'
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import logo from "../assets/img/logo.svg";
import bannerGraphic from "../assets/img/banner-img.png";
import bannerAsset1 from "../assets/img/banner-asset-1.png";
import bannerAsset2 from "../assets/img/banner-asset-2.svg";
import ai_logo from "../assets/img/ai_icon.svg";
import html5 from "../assets/img/html5.svg";
import adobe from "../assets/img/adobe.svg";
import angular from "../assets/img/angular.svg";
import aws from "../assets/img/aws.svg";
import bootstrap from "../assets/img/bootstrap.svg";
import capacitor from "../assets/img/capacitor.svg";
import codeigniter from "../assets/img/codeigniter.svg";
import cordova from "../assets/img/cordova.svg";
import figma from "../assets/img/figma.svg";
import illustrator from "../assets/img/illustrator.svg";
import ionic from "../assets/img/ionic.svg";
import javascript from "../assets/img/javascript.svg";
import mysql from "../assets/img/mysql.svg";
import nodejs from "../assets/img/nodejs.svg";
import photoshop from "../assets/img/photoshop.svg";
import php from "../assets/img/php.svg";
import python from "../assets/img/python.svg";
import react from "../assets/img/react.svg";
import reactnative from "../assets/img/reactnative.svg";
import redis from "../assets/img/redis.svg";
import sass from "../assets/img/sass.svg";
import xd from "../assets/img/xd.svg";


SwiperCore.use([ Autoplay ]);

const Homepage = () => {
  return (
    <div className='page-wrapper'>
        <div className='page-header container-fluid'>
            <div className='logo-wrap'>
                <div className='img-wrap'>
                    <img src={logo} alt='Logo' />
                </div>
            </div>

            <div className='menu-wrap'>
                <ul>
                {/* className='active'  */}
                    <li><a href="#home">Home</a></li>
                    <li><a href="#aboutUs">About Us</a></li>
                    <li><a href="#contactUs">Contact Us</a></li>
                </ul>
                {/* <div className='menu-icon'>
                    <i className="fa-solid fa-bars"></i>
                </div> */}
            </div>
        </div>

        <div className='banner' id="home">
            <div className='banner-graphic'>
                <img src={bannerGraphic} alt="Banner" />
            </div>
            <div className='design-asset'>
            <img className='asset1' src={bannerAsset1} alt="Banner" />
            </div>

            <div className='container'>
                <div className='banner-content'>
                    <h2>Empowering Your Digital World</h2>
                    <p>Transforming Businesses with Cutting-Edge IT Solutions and Exceptional Service</p>
                </div>
            </div>
        </div>
        <div className='info-section'>

            <div className='design-asset2'>
            <img className='asset2' src={bannerAsset2} alt="Banner" />

            </div>
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-md-12 col-lg-4'>
                    <div className='left-single-card'>
                        <div className='img-wrap'>
                        <img src={ai_logo} alt="logo" />
                        </div>
                        <div className='content'>
                            <p>We are passionate about creating innovative solutions that drive digital transformation. With a focus on cutting-edge technology, we provide tailored apps and services to empower businesses for success.</p>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-12 col-lg-8 py-3 py-lg-5 '>
                    <div className='row'>
                        <div className='col-12 col-md-6 mb-3'>
                            <div className='info-card'>
                                <div className='icon-wrap'>
                                    {/* <img src='' alt='' /> */}
                                    <i className="fa-solid fa-business-time"></i>
                                </div>
                                <div className='content'>
                                    <h2>IT Consultancy</h2>
                                    <p>Innovate with Expert IT Solutions.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 mb-3'>
                            <div className='info-card'>
                                <div className='icon-wrap'>
                                    {/* <img src='' alt='' /> */}
                                    <i className="fa-solid fa-shield-halved"></i>
                                </div>
                                <div className='content'>
                                    <h2>Cyber Security</h2>
                                    <p>Securing the digital frontier.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 mb-3'>
                            <div className='info-card'>
                                <div className='icon-wrap'>
                                    {/* <img src='' alt='' /> */}
                                    <i className="fa-solid fa-code"></i>
                                </div>
                                <div className='content'>
                                    <h2>Development</h2>
                                    <p>Innovating the Future, One Code at a Time.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 mb-3'>
                            <div className='info-card'>
                                <div className='icon-wrap'>
                                    {/* <img src='' alt='' /> */}
                                    <i className="fa-brands fa-uikit"></i>
                                </div>
                                <div className='content'>
                                    <h2>UX/UI Strategy</h2>
                                    <p>Crafting Seamless Experiences, Designing Tomorrow.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className='content card-bg py-4 px-4'>
                                <p>We specialise in providing comprehensive IT services and solutions to help your business thrive in the digital age, with years of experience, we have a proven track record in delivering top-notch IT services that drive success.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div className='parallax-section'  id="aboutUs">
            <div className='content'>
                <h3>“Our mission is to empower businesses with cutting-edge technology <br /> and exceptional support.”</h3>
            </div>
        </div>

        <div className='company-information-section'>
            <div className='container'>
            <div className='row'>
                <div className='col-12 col-lg-4 mb-4'>
                    <div className='info-card'>
                        <h3>About us</h3>
                        <p>Company Story: “With over years of experience, we have a proven track record in delivering top-notch IT services that drive success.”</p>
                        <ul>
                            <li>Mission Statement: “Our mission is to empower businesses with cutting-edge technology and exceptional support.”</li>
                            <li>Team: “Meet our dedicated team of experts who are passionate about delivering."</li>
                        </ul>
                    </div>
                </div>
                <div className='col-12 col-lg-4 mb-4'>
                    <div className='info-card'>
                        <h3>Services</h3>
                        <p>List of Services: “Explore our range of services, including IT consulting, cybersecurity, cloud solutions, and more.”</p>
                        <ul>
                            <li>Service Details: “Learn how each service can benefit your business and address your specific needs.”</li>
                        </ul>
                    </div>
                </div>  
                <div className='col-12 col-lg-4 mb-4'>
                    <div className='info-card'>
                        <h3>Success Stories:</h3>
                        <p>“Read about how our IT solutions have helped businesses achieve their goals and overcome challenges.”</p>
                        <ul>
                            <li>“Innovate with Expert IT Solutions.”</li>
                            <li>Reliable IT Services, Exceptional Results.</li>
                            <li>Elevate Your Digital Transformation Journey.</li>
                            <li>Empower Your Business with Technology.</li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <div className='tech-stack-section'>
            <div className='section-title'>
                <h2>Our Tech Stack</h2>
            </div>
           
            <div className='techstack-wrap container'>
                <Swiper spaceBetween={30}  slidesPerView={6}  breakpoints={{ 0: { slidesPerView: 3 }, 400:{ slidesPerView:4 }, 768:{ slidesPerView:8 } }}  autoplay={{  delay: 800, disableOnInteraction: true }}   scrollbar={{ draggable: true }} className="w-100">
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={javascript} alt="Tech logo" />
                        </div>
                        <h2>Javascript</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={nodejs} alt="Tech logo" />
                        </div>
                        <h2>NodeJS</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={angular} alt="Tech logo" />
                        </div>
                        <h2>Angular</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={ionic} alt="Tech logo" />
                        </div>
                        <h2>Ionic</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={capacitor} alt="Tech logo" />
                        </div>
                        <h2>Capacitor</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={cordova} alt="Tech logo" />
                        </div>
                        <h2>Cordova</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={react} alt="Tech logo" />
                        </div>
                        <h2>React</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={reactnative} alt="Tech logo" />
                        </div>
                        <h2>React Native</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={adobe} alt="Tech logo" />
                        </div>
                        <h2>Adobe InDesign</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={figma} alt="Tech logo" />
                        </div>
                        <h2>Figma</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={xd} alt="Tech logo" />
                        </div>
                        <h2>Adobe XD</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={illustrator} alt="Tech logo" />
                        </div>
                        <h2>Illustrator</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={photoshop} alt="Tech logo" />
                        </div>
                        <h2>Photoshop</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={html5} alt="Tech logo" />
                        </div>
                        <h2>HTML5</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={bootstrap} alt="Tech logo" />
                        </div>
                        <h2>Bootstrap</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={sass} alt="Tech logo" />
                        </div>
                        <h2>SASS</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={python} alt="Tech logo" />
                        </div>
                        <h2>Python</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={php} alt="Tech logo" />
                        </div>
                        <h2>PHP</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={codeigniter} alt="Tech logo" />
                        </div>
                        <h2>Codeigniter</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={mysql} alt="Tech logo" />
                        </div>
                        <h2>MySQL</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={redis} alt="Tech logo" />
                        </div>
                        <h2>Redis</h2>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='img-wrap'>
                            <img src={aws} alt="Tech logo" />
                        </div>
                        <h2>AWS</h2>
                    </SwiperSlide>
                </Swiper>
            </div>
            
        </div>

        <div className='parallax-section contact-section' id="contactUs">
            <div className='container'>
                <div className='row'>
                    <div className='col-md-7'>
                        <div className='content'>
                            <h3>Get Free Consultations For Any Kind Of IT Solutions ?</h3>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='contact-form-wrap'>
                            <div className='input-wrap'>
                                <input type="text" placeholder="Name" />
                            </div>
                            <div className='input-wrap'>
                                <input type="email" placeholder="Email" />
                            </div>
                            <div className='input-wrap'>
                                <textarea placeholder="Message" rows='5' />
                            </div>
                            <div className='input-wrap'>
                                <button type='submit' className='submit-btn'>Send Message</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='footer-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-6'>
                        {/* <p className='text-start m-0'>Website</p> */}
                    </div>
                    <div className='col-6'>
                        <p className='text-end m-0'>All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>


        <div className='scrollToTop' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
            <i className="fa-solid fa-arrow-up"></i>
        </div>
    </div>
  )
}

export default Homepage